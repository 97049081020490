<template>
  <Modal modalId="change-appointment-modal" :hide-header="true" hide-footer>
    <div class="row">
      <div class="col-md-12">
        <div class="fs-30px text-black fw-700">
          {{ $t("Change appointment") }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card bg-gray7">
          <div class="card-body">
            <strong>
              {{ $t("Who are the tenants?") }}
            </strong>
            <ul class="disc ml-4 mt-2" v-if="currentInvitation?.user?.tenants">
              <li v-for="(tenant, key) in currentInvitation.user.tenants" :key="key">
                {{ tenant.name }}
              </li>
            </ul>
            <strong>
              {{ $t("Current appointment") }}
            </strong>
            <ul class="disc ml-4 mt-2">
              <li>
                <span clsas="mr-2">
                  {{ currentInvitation.date | moment("DD-MM-YYYY") }}
                </span>
                <span class="mx-2">|</span>
                <span>{{ currentInvitation.time }} </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 pb-0">
        <div class="fw-700">
          {{ $t("Send a new viewing appointment") }}
        </div>
      </div>
    </div>
    <div class="mt-4">
      <strong> </strong>

      <p class="text-danger fw-bold text-capitalize" v-if="getErrors.invitation">
        {{ getErrors.invitation }}
      </p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="d-flex flex-column flex-sm-row">
            <div class="flex-1">
              <Datepicker
                name="invite-date"
                v-model="formData.date"
                rules="required"
                :label="$t('broker.requestsTable.inviteModal.date')"
                :placeholder="$t('broker.requestsTable.inviteModal.date')"
                :disablePastDates="true"
              />
            </div>
            <div class="listing__invite-time ml-sm-3 ml-0">
              <SelectInput
                name="invite-time"
                v-model="formData.time"
                rules="required"
                :label="$t('Time')"
              >
                <option
                  v-for="(option, i) in inviteTimeOptions"
                  :value="option.value"
                  :key="i"
                >
                  {{ option.text }}
                </option>
              </SelectInput>
            </div>
          </div>
          <div>
            <BButton
              type="submit"
              class="px-2"
              variant="primary"
              block
              :disabled="getLoadings.invitation"
            >
              {{ updateModalLocale.change }}
              <b-spinner
                v-if="getLoadings.invitation"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
            </BButton>
          </div>
        </b-form>
      </ValidationObserver>

      <p class="text-gray mt-4">
        {{
          $t(
            "Click below to cancel the appointment completely. No alternative option will be presented to the candidate."
          )
        }}
      </p>
    </div>

    <BButton
      :disabled="getLoadings.invitation"
      variant="outline-danger"
      @click="$emit('cancelInvite')"
      block
    >
      {{ $t("Cancel current viewing appointment") }}
    </BButton>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";
import { ValidationObserver } from "vee-validate";
import SelectInput from "../../ui/form/controls/SelectInput.vue";
import { mapGetters } from "vuex";
import Datepicker from "../../ui/form/controls/Datepicker.vue";
export default {
  props: {
    currentInvitation: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
    SelectInput,
    ValidationObserver,
    Datepicker,
  },
  data() {
    return {
      invitationStatus: null,
      formData: { date: "", time: "" },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    inviteTimeOptions() {
      const timeLocale = this.$t("broker.requestsTable.inviteModal.time");
      let hours = [{ text: timeLocale, value: "" }];
      let minutes = "";

      for (let i = 8; i <= 18; i++) {
        const hour = i < 10 ? `0${i}` : i;
        for (let j = 0; j < 4; j++) {
          minutes = j == 0 ? "00" : j * 15;
          hours.push({ text: `${hour}:${minutes}`, value: `${hour}:${minutes}` });
        }
      }
      return hours;
    },
    updateModalLocale() {
      return this.$t("broker.requestsTable.updateAppointmentModal");
    },
  },
  methods: {
    onSubmit() {
      console.log("submit");
      this.$emit("on-appointment-change", this.formData);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.listing {
  &__candiate-name {
    font-size: 1.875rem;
  }

  &__invite-time {
    flex-basis: 198px;

    @include xs {
      flex-basis: auto;
    }
  }
}
</style>
