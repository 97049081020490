<template>
  <Modal
    modalId="cancel-invite-modal"
    :hide-header="true"
    :hide-footer="true"
  >
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="fs-30px fw-700">
            {{ $t("Cancel invitation") }}
          </div>
          <p class="text-gray mt-2">
            {{ $t("What is the reason you want to report this candidate?") }}
          </p>
        </div>
      </div>
      <p
        class="text-danger fw-bold text-capitalize"
        v-if="getErrors.invitation_cancel && getErrors.form.length"
      >
        {{ getErrors.invitation_cancel }}
      </p>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div>
            <label class="text-dark">{{ $t("Reason") }}</label>
            <Textarea name="reason" v-model="form.reason" rules="required" />
          </div>
          <div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.report_to_admin"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t("I want to report this candidate to Eerlijktehuur") }}
            </b-form-checkbox>
          </div>

          <div class="mt-2" v-if="form.report_to_admin">
            <label class="text-dark">{{ $t("Reason of reporting:") }}</label>
            <Textarea name="reason" v-model="form.report_admin_reason" rules="required" />
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <BButton
                :disabled="getLoadings.invitation_cancel"
                type="submit"
                block
                variant="primary"
              >
                {{ $t("Submit reason") }}
                <b-spinner
                  v-if="getLoadings.invitation_cancel"
                  label="Spinning"
                  small
                  variant="white"
                ></b-spinner>
              </BButton>
            </div>
            <div class="col-md-12 pt-0">
              <button class="btn btn-white btn-block" type="button" @click="goBack">
                {{ $t("Go back to invitation") }}
              </button>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </div>

    <template #footer> </template>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";

import { ValidationObserver } from "vee-validate";
import Textarea from "../../ui/form/controls/Textarea.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    currentInvitation: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
    ValidationObserver,
    Textarea,
  },
  data() {
    return {
      form: {
        reason: "",
        report_to_admin: false,
        report_admin_reason: "",
      },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    reportReasons() {
      return [
        { text: this.reportLocale.reasons[0], value: "" },
        { text: this.reportLocale.reasons[1], value: "reason1" },
        { text: this.reportLocale.reasons[2], value: "reason2" },
        { text: this.reportLocale.reasons[3], value: "reason3" },
      ];
    },
    inviteTimeOptions() {
      let hours = [{ text: "Select viewing time", value: "" }];

      for (let i = 9; i <= 17; i++) {
        const hour = i < 10 ? `0${i}` : i;
        hours.push({ text: `${hour}:00`, value: `${hour}:00` });
      }
      return hours;
    },
    reportLocale() {
      return this.$t("broker.assigningTable.invitation_cancelModal");
    },
  },
  methods: {
    onSubmit() {
      this.$emit("on-cancel", this.form);
    },
    goBack() {
      this.$bvModal.hide("cancel-invite-modal");
      if (this.currentInvitation.date && this.currentInvitation.time) {
        this.$bvModal.show("change-appointment-modal");
      } else {
        this.$bvModal.show("invite-modal");
      }
    },
  },
};
</script>

<style lang="scss">
.report-modal {
  &__candiate {
    font-size: 1.875rem;
    @include xs {
      font-size: 1.5rem;
    }
  }
  &__btn {
    width: 13.875rem;
  }
  &__comment {
    textarea {
      padding: 1rem 0.75rem;
    }
  }
}
</style>
