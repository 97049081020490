<template>
  <BContainer>
    <div v-if="!getLoadings.viewingRequests">
      <h2 class="fw-bold heading text-black mb-2" v-if="listing.title">
        {{ listing.title }}
      </h2>
      <ReportCandidateModal @report-candidate="candidateReport" />

      <InviteModal
        @on-invitation="onInvitation"
        :no-close-on-backdrop="false"
        v-if="getSelectedRowData"
        :currentInvitation="getSelectedRowData"
        @cancelInvite="cancelInvite"
      />

      <CancelInvitationModal
        :no-close-on-backdrop="false"
        :currentInvitation="getSelectedRowData"
        @on-cancel="onCancel"
      />
      <ChangeAppointmentModal
        v-if="getSelectedRowData"
        @on-appointment-change="onAppoinmentChange"
        :currentInvitation="getSelectedRowData"
        @cancelInvite="cancelInvite"
      />

      <CloseRequestModal :listing="listing" />

      <!-- <b-modal id="confirm-assigning" ref="confirm-assigning" hide-header hide-footer centered> -->
      <Modal modalId="confirm-assigning" :hide-header="true" :hide-footer="true">
        <h1>
          {{ $t("Start assigning") }}
        </h1>
        <p>
          {{
            $t(
              "You are about to start the assigning phase, press the button below to confirm this action."
            )
          }}
        </p>
        <div class="row mt-4">
          <div class="col-md-12">
            <button
              class="btn btn-primary btn-block"
              @click="onAssigning"
              :disabled="getLoadings.assigning"
            >
              <b-spinner
                v-if="getLoadings.assigning"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
              {{ $t("Start assigning") }}
            </button>
          </div>
          <div class="col-md-12">
            <button
              class="btn btn-white btn-block"
              @click="$bvModal.hide('confirm-assigning')"
            >
              {{ $t("Cancel") }}
            </button>
          </div>
        </div>
      </Modal>

      <Table
        :emptyState="requestsTableLocale.emptyState"
        :rows="getViewingRequests"
        :columns="columns"
        @rowSelect="onRowSelect"
        :excludeFromClickOutside="[
          '#invite-modal',
          '#change-appointment-modal',
          '.mx-calendar',
          '#report-candidate-modal',
          '#cancel-invite-modal',
        ]"
      >
        <template #custom-html-cell="{ field, value, row }">
          <!-- user -->
          <template v-if="field === 'userName'">
            {{ row.status }}
            <span class="text-capitalize" v-if="row.status == 3">
              {{ value }}
              <i
                class="fa-solid fa-circle-info ml-2 cursor-pointer"
                @click="selectedUser = row.user"
                v-b-modal.info-modal
              ></i>
            </span>
            <span v-else>
              {{ row.code }}
            </span>
          </template>

          <!-- Income -->
          <template v-if="field === 'sufficientIncome'">
            <TickBox
              :id="`sufficientIncome-professional-${row.id}`"
              fill="#ffcb00"
              v-if="row.profesional_less_than_2"
            />
            <TickBox
              :id="`sufficientIncome-${row.id}`"
              fill="#0a9250"
              v-else-if="value"
            />
            <TimesBox :id="`notSufficientIncome-${row.id}`" v-else />

            <b-tooltip
              custom-class="custom-tooltip"
              :target="`sufficientIncome-professional-${row.id}`"
              placement="right"
            >
              Contains a tenant with less than 2 years entrepreneurship
            </b-tooltip>
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`sufficientIncome-${row.id}`"
              placement="right"
            >
              Income sufficient
            </b-tooltip>
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`notSufficientIncome-${row.id}`"
              placement="right"
            >
              Income not-sufficient
            </b-tooltip>
          </template>

          <!-- Household -->
          <template v-else-if="field === 'houseHold'">
            <Person
              :id="`household_${row.id}`"
              :fill="getHouseholdColor(value)"
              v-if="value === 'single'"
            />
            <People
              :id="`household_${row.id}`"
              :fill="getHouseholdColor(value)"
              v-else-if="value === 'couple'"
            />
            <Team
              :id="`household_${row.id}`"
              :fill="getHouseholdColor(value)"
              v-else-if="value === 'family'"
            />
            <Group :id="`household_${row.id}`" :fill="getHouseholdColor(value)" v-else />
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`household_${row.id}`"
              placement="top"
            >
              <template v-if="value === 'single'">
                {{ $t("Single") }} - {{ row.user.tenants.length }} main tenant(s)
              </template>
              <template v-else-if="value === 'couple'">
                {{ $t("Couple") }} - {{ row.user.tenants.length }} main tenant(s)
              </template>
              <template v-else-if="value === 'couple'">
                {{ $t("Family") }} - {{ row.user.tenants.length }} main tenant(s)
              </template>
              <template v-else>
                {{ $t("Group occupancy") }} - {{ row.user.tenants.length }} main tenant(s)
              </template>
            </b-tooltip>
          </template>

          <!-- Invited -->
          <template v-else-if="field === 'status'">
            <HourglassHalf v-if="row.status == 1 && row.accepted_at == '-'" />
            <TimesBox v-else-if="row.status === 2" />
            <TickBox fill="#0a9250" v-else-if="row.status == 3" />
            <span class="custom-vgt-table__empty rounded-circle d-block" v-else> </span>
          </template>
          <!-- Invited -->
          <template v-else-if="field === 'rent_period'">
            <strong v-if="row.user.rent_period" :id="`rent_period_${row.id}`">
              {{ row.availability_date }} <br />
              {{ $t(row.user.rent_period) }}
            </strong>
            <span class="custom-vgt-table__empty rounded-circle d-block" v-else> </span>
            <b-tooltip
              v-if="row.user.rent_period == 'temporary'"
              custom-class="custom-tooltip"
              :target="`rent_period_${row.id}`"
              placement="bottom"
            >
              {{ row.user.rent_months + " " + $t("Months") }}
            </b-tooltip>
          </template>

          <!-- Availability-slots -->
          <template v-else-if="field === 'availability_score'">
            <span
              class="d-block listing-requests__availability-slots rounded-circle"
              :id="`availability_score_${row.id}`"
              :class="getAvailabilityColor(value)"
            ></span>
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`availability_score_${row.id}`"
              placement="right"
            >
              <span v-if="value <= 2"> Low availability </span>
              <span v-else-if="value > 2 && value <= 4"> Medium availability </span>
              <span v-else> High availability </span>
            </b-tooltip>
          </template>

          <!-- KYC -->
          <template v-else-if="field === 'sanction'">
            <span
              v-if="row.sanction == 'red'"
              class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-danger"
              :id="`kyc-${row.id}`"
            ></span>
            <span
              v-if="row.sanction == 'yellow'"
              class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-warning"
              :id="`kyc-${row.id}`"
            ></span>
            <span
              v-if="row.sanction == 'green'"
              class="d-block listing-requests__availability-slots wh-20px rounded-circle bg-primary"
              :id="`kyc-${row.id}`"
            ></span>
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`kyc-${row.id}`"
              placement="right"
              v-if="row.sanction == 'red'"
            >
              <ul class="px-4 py-2 mb-0 list-style-dots">
                <li v-for="flag in row.flags" :key="flag">{{ flag }}</li>
              </ul>
            </b-tooltip>
            <b-tooltip
              custom-class="custom-tooltip"
              :target="`kyc-${row.id}`"
              placement="right"
              v-if="row.sanction == 'green'"
            >
              No results found
            </b-tooltip>
          </template>
          <template v-else-if="field === 'time'">
            <span v-if="row.status == 2">
              {{ $t("Canceled") }}
            </span>
            <span
              v-else
              :class="{ 'text-muted': row.status == 1 && row.accepted_at == null }"
            >
              {{ `${row.date} ${row.time}` | moment("DD-MM-YYYY  HH:mm") }}
            </span>
          </template>
        </template>
        <template #custom-table-actions>
          <div class="d-flex table-actions justify-content-end flex-column flex-sm-row">
            <BButton variant="white" class="mr-3" @click="exportOverview">
              {{ $t("Export overview") }}
              <b-spinner
                v-if="getLoadings.appointment_export_overview"
                small
                label="Spinning"
                class="ml-2"
              ></b-spinner>
            </BButton>
            <BButton
              variant="white"
              class="mr-3"
              v-b-modal.close-request-modal
              :disabled="listing.disable_viewing == 1"
            >
              {{
                listing.disable_viewing == 1
                  ? $t("Request disabled")
                  : $t("Disable request")
              }}
            </BButton>
            <div
              v-b-tooltip.hover
              :title="selectedRowId ? '' : 'Select a candidate to invite'"
            >
              <BButton
                variant="primary"
                :disabled="
                  !selectedRowId ||
                  getSelectedRowData.status == true ||
                  getSelectedRowData.status == false
                "
                @click="openInviteModal"
                >{{ $t("Invite candidate") }}</BButton
              >
            </div>
            <BButton
              v-b-modal.change-appointment-modal
              class="ml-sm-3 ml-0 mt-3 mt-sm-0"
              variant="white"
              @click="appointment_id = getSelectedRowData.id"
              v-if="
                !(
                  !selectedRowId ||
                  !getSelectedRowData.date ||
                  getSelectedRowData.status == 2
                )
              "
              :disabled="
                getSelectedRowData.status === false || getSelectedRowData.status == null
              "
              >{{ $t("Change appointment") }}</BButton
            >
            <BButton
              v-b-modal.report-candidate-modal
              class="ml-sm-3 ml-0 mt-3 mt-sm-0"
              variant="white"
              v-if="
                selectedRowId && getSelectedRowData.date && getSelectedRowData.status != 2
              "
            >
              {{ $t("Report candidate") }}
            </BButton>
            <BButton
              v-b-modal.confirm-assigning
              class="ml-sm-3 ml-0 mt-3 mt-sm-0"
              variant="white"
              :disabled="canStartAssigning"
            >
              {{ $t("Start assigning") }}
            </BButton>
          </div>
        </template>
      </Table>
    </div>
    <Spinner v-else />

    <Modal
      modalId="info-modal"
      :no-close-on-backdrop="false"
      :no-close-on-esc="false"
      :hide-header="true"
      :hide-footer="true"
    >
      <div class="row" v-if="selectedUser">
        <div class="col-md-12">
          <h1>
            {{ $t("Contact information") }}
          </h1>
        </div>
        <div class="col-md-12">
          <div class="border p-3 py-4 bg-gray7 rounded">
            <div>
              {{ $t("Name") }}:

              <strong>
                {{ selectedUser.first_name }}
                {{ selectedUser.last_name }}
              </strong>
            </div>
            <div class="mt-2">
              {{ $t("Email") }}:

              <strong>
                {{ selectedUser.email }}
              </strong>
            </div>
            <div class="mt-2">
              {{ $t("Phone") }}:

              <strong>
                {{ selectedUser.tenants[0].phone_number }}
              </strong>
            </div>
            <div class="mt-2">
              {{ $t("Registration date") }}:

              <strong>
                {{ selectedUser.email_verified_at | moment("DD-MM-YYYY  HH:mm") }}
              </strong>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <BButton variant="outline-danger" @click="$bvModal.hide('info-modal')" block>
            {{ $t("Cancel") }}
          </BButton>
        </div>
      </div>
    </Modal>
  </BContainer>
</template>

<script>
import Table from "@/components/ui/tables/Table.vue";
import InviteModal from "@/components/Broker/RequestsTable/InviteModal.vue";
import ChangeAppointmentModal from "@/components/Broker/RequestsTable/ChangeAppointmentModal.vue";
import TimesBox from "@/components/svg/TimesBox.vue";
import HourglassHalf from "@/components/svg/HourglassHalf.vue";
import TickBox from "@/components/svg/TickBox.vue";
import Person from "@/components/svg/Person.vue";
import People from "@/components/svg/People.vue";
import Team from "@/components/svg/Team.vue";
import { mapGetters, mapActions } from "vuex";
import Spinner from "@/components/ui/Spinner.vue";
import Group from "../../components/svg/Group.vue";
import Modal from "../../components/ui/Modal.vue";
import ReportCandidateModal from "../../components/Broker/AssigningTable/ReportCandidateModal.vue";
import CancelInvitationModal from "../../components/Broker/RequestsTable/CancelInvitationModal.vue";
import CloseRequestModal from "./CloseRequestModal.vue";

export default {
  components: {
    Table,
    InviteModal,
    ChangeAppointmentModal,
    TimesBox,
    TickBox,
    Person,
    People,
    Team,
    Spinner,
    Group,
    Modal,
    ReportCandidateModal,
    CancelInvitationModal,
    CloseRequestModal,
    HourglassHalf,
  },
  computed: {
    ...mapGetters("requestsAndAppointment", ["getViewingRequests", "listing"]),
    ...mapGetters("loadingError", ["getLoadings"]),
    getSelectedRowData() {
      if (!this.selectedRowId) return {};
      return this.getViewingRequests.find((req) => req.id == this.selectedRowId);
    },
    listingId() {
      return this.$route.params.listingId;
    },
    randomId() {
      return `K-${Math.round(Math.random() * 1000000)}`;
    },
    requestsTableLocale() {
      return this.$t("broker.requestsTable");
    },
    columns() {
      return [
        {
          label: "User",
          field: "userName",
          html: true,
        },
        {
          label: "Income",
          field: "sufficientIncome",
          type: "boolean",
          html: true,
        },
        {
          label: "Household",
          field: "houseHold",
          html: true,
        },
        {
          label: "Rental period",
          field: "rent_period",
          html: true,
        },
        {
          label: "Availability",
          field: "availability_score",
          html: true,
        },
        {
          label: "KYC",
          field: "sanction",
          html: true,
        },
        {
          label: "Invited",
          field: "status",
          html: true,
          type: "boolean",
        },
        {
          label: "Appointment",
          field: "time",
          html: true,
        },
        {
          field: "flags",
          hidden: true,
        },
        {
          label: "",
          field: "code",
          hidden: true,
        },
        {
          field: "code",
          hidden: true,
        },
        {
          field: "date",
          hidden: true,
        },
        {
          field: "profesional_less_than_2",
          hidden: true,
        },
        {
          field: "id",
          hidden: true,
        },
        {
          field: "user",
          hidden: true,
        },
        {
          field: "availability_date",
          hidden: true,
        },
        {
          field: "accepted_at",
          hidden: true,
        },
      ];
    },
    listingTitle() {
      const propertyTypes = this.$t("singleListing.propertyType");
      const listingTitleArr = this.listing.title.split(" ");
      listingTitleArr[0] = propertyTypes[listingTitleArr[0].toLowerCase()];
      return listingTitleArr.join(" ");
    },
    preferredHousehold() {
      return this.getViewingRequests.length
        ? this.getViewingRequests[0].listing.preferredHousehold
        : null;
    },
    canStartAssigning() {
      let flag = true;

      for (let request of this.getViewingRequests) {
        if (request.date && request.status != 2) {
          //status = 2 = canceled
          flag = false;
          break;
        }
      }

      return flag || this.getLoadings.assigning || !this.getViewingRequests.length;
    },
  },
  data() {
    return {
      selectedRowId: null,
      appointment_id: null,
      selectedUser: null,
    };
  },
  methods: {
    ...mapActions("report", ["reportCandidate"]),
    ...mapActions("requestsAndAppointment", [
      "setViewingRequests",
      "changeInvitationStatus",
      "appointmentCancel",
      "appointmentInvite",
      "startAssigning",
      "exportOverview",
    ]),

    getAvailabilityColor(value) {
      if (value <= 2) return "bg-danger";
      if (value > 2 && value <= 4) return "bg-warning";
      return "bg-primary";
    },
    onRowSelect(rowId) {
      this.selectedRowId = rowId;
    },
    async onInvitation({ date, time }) {
      try {
        let appointment_id = this.appointment_id;
        await this.appointmentInvite({ appointment_id, date, time });
        this.$bvModal.hide("invite-modal");
        localStorage.setItem(`invite_modal_id_${this.listingId}`, "");
        this.setViewingRequests({ listingId: this.listingId });
      } catch (error) {
        console.log(error.message);
      }
    },
    async onCancel(data) {
      try {
        data.appointment_id = this.selectedRowId;
        await this.appointmentCancel(data);
        this.$bvModal.hide("invite-modal");
        localStorage.setItem(`invite_modal_id_${this.listingId}`, "");
        this.setViewingRequests({ listingId: this.listingId });
      } catch (error) {
        console.log(error.message);
      }
    },
    async onAppoinmentChange({ date, time }) {
      try {
        await this.appointmentInvite({ appointment_id: this.appointment_id, date, time });
        let listingId = this.listingId;
        this.setViewingRequests({ listingId });
        this.$bvModal.hide("change-appointment-modal");
      } catch (error) {
        console.log(error.message);
      }
    },
    async onAssigning() {
      const url = `/broker/listings-assign/${this.listing.id}`;
      if (this.listing.status !== "Assign a candidate") {
        await this.startAssigning();
      }

      this.$router.push(url);
    },

    getHouseholdColor(household) {
      if (!this.preferredHousehold) return "#0a9250";
      return this.preferredHousehold.toLowerCase().indexOf(household.toLowerCase()) > -1
        ? "#0a9250"
        : "#FE5F55";
    },
    async candidateReport(body) {
      console.log("this.selectedRowId", this.selectedRowId, this.getSelectedRowData);
      body.user_id = this.selectedRowId;
      await this.reportCandidate(body);
      this.$bvModal.hide("report-candidate-modal");
    },
    cancelInvite() {
      this.$bvModal.hide("invite-modal");
      this.$bvModal.hide("change-appointment-modal");
      this.$bvModal.show("cancel-invite-modal");
    },
    openInviteModal() {
      this.appointment_id = this.getSelectedRowData.id;
      this.$bvModal.show("invite-modal");
      localStorage.setItem(`invite_modal_id_${this.listingId}`, this.selectedRowId);
    },
  },
  async created() {
    const listingId = this.$route.params.listingId;
    await this.setViewingRequests({ listingId });
    let invite_modal_id = localStorage.getItem(`invite_modal_id_${listingId}`);
    console.log("invite_modal_id", invite_modal_id);
    if (invite_modal_id) {
      this.selectedRowId = invite_modal_id;
      this.openInviteModal();
    }
  },
};
</script>

<style lang="scss" scoped>
.listing-requests {
  &__availability-slots {
    width: 20px;
    height: 20px;
  }
}
</style>
