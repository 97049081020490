<template>
  <Modal
    modalId="invite-modal"
    :no-close-on-backdrop="true"
    :hide-header="true"
    :hide-footer="true"
    :size="showAppointments ? 'xl' : 'md'"
  >
    <div class="row">
      <div
        class="col-md-6"
        :class="{
          'col-md-6': showAppointments,
          'col-md-12': showAppointments == false,
        }"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="fs-20 fw-700">
              {{ $t("Invite candidate(s)") }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card bg-gray7">
              <div class="card-body">
                <strong>
                  {{ $t("Who are the tenants?") }}
                </strong>
                <ul class="disc ml-4 mt-2" v-if="currentInvitation?.user?.tenants">
                  <li v-for="(tenant, key) in currentInvitation.user.tenants" :key="key">
                    {{ tenant.name }}
                  </li>
                </ul>
                <strong>
                  {{ $t("Viewing preferences of tenant(s)") }}
                </strong>
                <ul class="disc ml-4 mt-2" v-if="currentInvitation?.user?.tenants">
                  <li v-for="(schedule, i) in currentInvitation.schedules" :key="i">
                    <span clsas="mr-2">
                      {{ schedule.date | moment("DD-MM-YYYY") }}
                    </span>
                    <span class="mx-2">|</span>
                    <span>{{ schedule.time }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="fs-20 fw-700">
              {{ $t("Send an invite to the candidate") }}
            </div>
          </div>
        </div>
        <p class="text-danger fw-bold text-capitalize" v-if="getErrors.invitation">
          {{ getErrors.invitation }}
        </p>
        <ValidationObserver ref="observer-step1" v-slot="{ handleSubmit }" slim>
          <b-form id="invitation-form" @submit.prevent="handleSubmit(onInvitation)">
            <div class="row">
              <div class="col-md-7">
                <Datepicker
                  label="Date"
                  name="date"
                  rules="required"
                  placeholder="Date"
                  disablePastDates="true"
                  v-model="form.date"
                  @input="onChange"
                />
              </div>

              <div class="col-md-5">
                <SelectInput
                  label="Time"
                  name="time"
                  rules="required"
                  placeholder="Time"
                  v-model="form.time"
                >
                  <option
                    v-for="({ value, text }, i) in inviteTimeOptions"
                    :key="i"
                    :value="value"
                  >
                    {{ text }}
                  </option>
                </SelectInput>
              </div>
            </div>

            <slot name="formfooter">
              <div class="row">
                <div class="col-md-12">
                  <BButton
                    variant="primary"
                    block
                    type="submit"
                    @click="status = true"
                    :disabled="getLoadings.invitation"
                  >
                    <b-spinner
                      v-if="getLoadings.invitation && status === true"
                      small
                      label="Spinning"
                      variant="white"
                    ></b-spinner>
                    {{ $t("Send invitation") }}
                  </BButton>
                </div>
                <div class="col-md-12 pt-0">
                  <BButton
                    :disabled="getLoadings.invitation"
                    variant="outline-danger"
                    block
                    @click="$emit('cancelInvite')"
                  >
                    <b-spinner
                      v-if="getLoadings.invitation && status === false"
                      small
                      label="Spinning"
                      variant="danger"
                    ></b-spinner>
                    {{ $t("Cancel invitation") }}
                  </BButton>
                </div>
              </div>
            </slot>
          </b-form>
        </ValidationObserver>
      </div>
      <div class="col-md-6" v-if="showAppointments">
        <div class="col-md-12">
          <div class="fs-20 fw-700">
            {{ $t("My agenda on") }}
            {{ form.date }}
          </div>
        </div>
        <div class="col-md-12">
          <div v-if="!getLoadings.calendar && appointments.length == 0">
            No appointments today
          </div>
          <div class="text-center" v-if="getLoadings.calendar">
            <div class="spinner-grow text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            class="row border-bottom"
            v-for="(appointment, index) in appointments"
            v-bind:key="index"
          >
            <div class="col-md-2">
              <span class="text-muted fs-16px">
                <span>{{
                  `${appointment.date} ${appointment.time}` | moment("HH:mm")
                }}</span>
              </span>
            </div>
            <div class="col-md-10">
              <div class="text-black fs-16px">
                {{ appointment.listing.title }}
              </div>
              <small class="text-muted">
                {{ appointment.user.first_name }}
                {{ appointment.user.last_name }} .
              </small>
              <small class="text-muted" v-if="appointment.user.tenants.length == 0">
                {{ $t("No Tenant Found") }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "../../ui/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Datepicker from "../../ui/form/controls/Datepicker.vue";
import SelectInput from "../../ui/form/controls/SelectInput.vue";

export default {
  props: {
    currentInvitation: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
    ValidationObserver,
    Datepicker,
    SelectInput,
  },
  data() {
    return {
      status: null,
      form: {
        date: "",
        time: "",
      },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("calendar", ["appointments"]),
    inviteTimeOptions() {
      let hours = [{ text: "Time", value: "" }];
      let minutes = "";

      for (let i = 8; i <= 18; i++) {
        const hour = i < 10 ? `0${i}` : i;
        for (let j = 0; j < 12; j++) {
          minutes = j * 5;
          minutes = `0${minutes}`.slice(-2);
          hours.push({ text: `${hour}:${minutes}`, value: `${hour}:${minutes}` });
        }
      }
      return hours;
    },
    showAppointments() {
      return this.form.date ? true : false;
    },
  },
  mounted() {},
  methods: {
    ...mapActions("calendar", ["getAppointments"]),
    onInvitation() {
      this.$emit("on-invitation", {
        date: this.form.date,
        time: this.form.time,
        status: this.status,
      });
    },
    onChange(data) {
      console.log("data", data);
      this.getAppointments({ date: this.form.date });
    },
  },
};
</script>

<style lang="scss" scoped>
.listing {
  &__candiate-name {
    font-size: 1.875rem;
    @include xs {
      font-size: 1.5rem;
    }
  }
  &__invite-time {
    flex-basis: 220px;
    @include xs {
      flex-basis: auto;
    }
  }
}
</style>
