<template>
  <Modal modalId="close-request-modal" :hide-header="true" hide-footer>
    <div class="row">
      <div class="col-md-12 py-0">
        <div class="fs-30px text-black fw-700">
          {{ $t("Close viewing request") }}
        </div>
      </div>

      <div class="col-md-12 py-0">
        <p class="text-gray">
          {{ $t("You are about to start the close listing for new viewing requests.") }}
        </p>
      </div>
      <div class="col-md-12" v-if="getErrors.update_listing">
        <p class="text-danger fw-bold text-capitalize">
          {{ getErrors.update_listing }}
        </p>
      </div>
      <div class="col-md-12" v-if="getSuccess.update_listing">
        <div class="alert alert-success" role="alert">
          {{ getSuccess.update_listing }}
        </div>
      </div>
      <div class="col-md-12">
        <BButton
          type="submit"
          class="mb-2"
          variant="primary"
          block
          :disabled="getLoadings.update_listing"
          @click="disableViewingRequest(listing.id)"
        >
          {{ $t("Close for new viewing requests") }}
          <b-spinner
            v-if="getLoadings.update_listing"
            small
            label="Spinning"
            variant="white"
          ></b-spinner>
        </BButton>
      </div>
    </div>

    <BButton variant="outline-danger" @click="$bvModal.hide('close-request-modal')" block>
      {{ $t("Cancel") }}
    </BButton>
  </Modal>
</template>

<script>
import Modal from "@/components/ui/Modal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    listing: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      invitationStatus: null,
      formData: { date: "", time: "" },
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors", "getSuccess"]),
    updateModalLocale() {
      return this.$t("broker.requestsTable.updateAppointmentModal");
    },
  },
  methods: {
    ...mapActions("listing", ["disableViewingRequest"]),
    onSubmit() {
      console.log("submit");
      this.$emit("on-appointment-change", this.formData);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.listing {
  &__candiate-name {
    font-size: 1.875rem;
  }

  &__invite-time {
    flex-basis: 198px;

    @include xs {
      flex-basis: auto;
    }
  }
}
</style>
